<div class="title">{{ 'finance.payout' | translate }}</div>

<form class="row" [formGroup]="form">
  <rp-input formControlName="amountCents" [matcher]="matcher" type="number" [placeholder]="'finance.amount' | translate" rpIntegerOnly />

  <rp-select
    formControlName="walletId"
    [items]="paymentMethods()"
    [placeholder]="'finance.method' | translate"
    (onScroll)="onScroll()"
    displayField="walletName"
    selectedField="walletId"
  />
</form>

<rp-button class="margin-top" (click)="makePayout()" [disabled]="!form.valid" fullWidth>
  {{ 'finance.makeRequest' | translate }}
</rp-button>
