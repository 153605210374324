import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  computed,
  ContentChild,
  DestroyRef,
  effect,
  inject,
  input,
  OnInit,
  signal,
  TemplateRef,
  viewChild,
  output,
} from '@angular/core';
import { NgFor, NgTemplateOutlet } from '@angular/common';
import {
  FormControl,
  FormControlDirective,
  FormControlName,
  FormControlStatus,
  NgModel,
  ReactiveFormsModule,
} from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
  SubscriptSizing,
} from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ErrorStateMatcher } from '@angular/material/core';

import { MatSelectSearchComponent, NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NoopValueAccessorDirective } from '@rp/shared/directives';
import { FieldErrorStateMatcher, injectNgControl } from '@rp/utils';
import { Validation } from '@rp/shared/validators';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { IconComponent, IconName } from '../icon';
import { SpinnerComponent } from '../spinner';

@Component({
  selector: 'rp-select',
  standalone: true,
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss',
  hostDirectives: [NoopValueAccessorDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    NgFor,
    NgxMatSelectSearchModule,
    IconComponent,
    TranslateModule,
    SpinnerComponent,
    NgTemplateOutlet,
  ],
  providers: [
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } },
    { provide: ErrorStateMatcher, useClass: FieldErrorStateMatcher },
  ],
})
export class SelectComponent<T> implements OnInit {
  @ContentChild('itemTpl') itemTpl: TemplateRef<unknown>;

  selectSearch = viewChild(MatSelectSearchComponent);

  label = input<string>();
  displayField = input<keyof T>();
  hiddenField = input<keyof T>('isHidden' as keyof T);
  selectedField = input<keyof T>();
  items = input.required<T[] | null>();
  icons = input<IconName[]>([]);
  placeholder = input<string>('');
  subscriptSizing = input<SubscriptSizing>('fixed');
  compareObjectWithField = input<keyof T>('id' as keyof T);
  isDisabled = input(false, {
    transform: booleanAttribute,
  });
  multiple = input(false, {
    transform: booleanAttribute,
  });
  searchable = input(false, {
    transform: booleanAttribute,
  });
  showErrorMessage = input(true, {
    transform: booleanAttribute,
  });
  translateOptions = input(false, {
    transform: booleanAttribute,
  });
  loading = input(false, {
    transform: booleanAttribute,
  });
  showCountryIcons = input(false, {
    transform: booleanAttribute,
  });
  matcher = input<FieldErrorStateMatcher>();
  clearable = input(false, {
    transform: booleanAttribute,
  });

  onChange = output<string[]>();
  onScroll = output();

  firstError = signal<string>('');
  errors = signal<Validation[]>([]);

  panelClass = computed<string>(() => {
    let panelClass = 'rp-select-panel';

    if (this.searchable()) {
      panelClass = `${panelClass} with-search`;
    }

    if (this.multiple()) {
      panelClass = `${panelClass} with-multiple`;
    }

    const isAllItemsHidden = this.items()?.every(item => item[this.hiddenField()]);

    if (isAllItemsHidden) {
      panelClass = `${panelClass} with-empty-list`;
    }

    return panelClass;
  });

  filteredItems = computed<T[]>(() => {
    let search = this._value().trim();

    if (!this.loading() && this.items()) {
      if (!search) {
        return [...this.items()];
      } else {
        search = search.toLowerCase();

        return this.items().filter(item => {
          if (this.displayField() && item[this.displayField()]) {
            return item[this.displayField()].toString().toLowerCase().includes(search);
          }

          return item.toString().toLowerCase().includes(search);
        });
      }
    } else {
      return [];
    }
  });

  ngControl: FormControlDirective | FormControlName | NgModel = injectNgControl();
  filterControl: FormControl<string> = new FormControl<string>('');
  isPrimitiveValue = false;
  iconNames = IconName;

  private originalOptionsLengthOffsetFn: () => number;
  private scrollPanel: HTMLElement | null = null;
  private lastScrollHeight = 0;

  private _value = signal<string>('');
  private _destroyRef = inject(DestroyRef);

  constructor() {
    effect(() => {
      const { control } = this.ngControl;
      if (this.isDisabled()) {
        control.disable();
      } else {
        control.enable();
      }
    });
  }

  ngOnInit(): void {
    this._listenFilterControlChanges();
    this._listenControlStatusChanges();
  }

  onClosed(): void {
    this.filterControl.setValue('');

    if (this.selectSearch() && this.originalOptionsLengthOffsetFn) {
      this.selectSearch()['getOptionsLengthOffset'] = this.originalOptionsLengthOffsetFn;
    }
  }

  attachScrollEvent(): void {
    this.scrollPanel = document.querySelector('.rp-select-panel');
    if (this.scrollPanel) {
      this.scrollPanel.addEventListener('scroll', this.onLazyScrollHandler.bind(this));
    }
  }

  detachScrollEvent(): void {
    if (this.scrollPanel) {
      this.scrollPanel.removeEventListener('scroll', this.onLazyScrollHandler.bind(this));
      this.scrollPanel = null;
    }
  }

  onLazyScrollHandler(event: Event): void {
    const target = event.target as HTMLElement;
    const bottomReached = target.scrollTop + target.clientHeight >= target.scrollHeight - 1;

    if (bottomReached && !this.loading() && target.scrollHeight > this.lastScrollHeight) {
      this.onScroll.emit();
      this.lastScrollHeight = target.scrollHeight;
    }
  }

  onOpened(): void {
    if (this.selectSearch()) {
      this.originalOptionsLengthOffsetFn = this.selectSearch()['getOptionsLengthOffset'].bind(
        this.selectSearch(),
      );

      this.selectSearch()['getOptionsLengthOffset'] = () => {
        const control = this.ngControl.control;

        if (this.multiple() && control.value?.length) {
          return this.originalOptionsLengthOffsetFn() + control.value.length;
        } else {
          return this.originalOptionsLengthOffsetFn();
        }
      };
    }
  }

  getSelectedItem(values: T | T[]): T | T[] {
    if (Array.isArray(values)) {
      return values.map(value =>
        this.items()?.find(item =>
          this.selectedField() ? item[this.selectedField()] === value : item === value,
        ),
      );
    }

    return this.items()?.find(item =>
      this.selectedField() ? item[this.selectedField()] === values : item === values,
    );
  }

  onClear(ev: MouseEvent): void {
    ev.stopPropagation();
    this.ngControl.control.reset();
  }

  compareWithFn = (option: T, value: T): boolean => {
    const compareField = this.compareObjectWithField();

    if (option === null || value === null) {
      return false;
    }

    if (typeof value === 'object' && !Array.isArray(value)) {
      return option[compareField] === value[compareField];
    }

    const optionValue = typeof option === 'object' ? option[compareField] : option;

    return optionValue === value;
  };

  private _listenFilterControlChanges(): void {
    this.filterControl.valueChanges
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((value: string) => {
        this._value.set(value);
      });
  }

  private _listenControlStatusChanges(): void {
    this.ngControl.control.statusChanges
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((status: FormControlStatus) => {
        if (status === 'INVALID') {
          const { control } = this.ngControl;

          this.errors.set(Object.keys(control.errors ?? {}) as Validation[]);
          this.firstError.set(this.errors().length ? 'validations.' + this.errors()[0] : '');
        }
      });
  }
}
