import { DatePipe } from '@angular/common';

import { Entity } from '@rp/shared/models';

import { PayoutFilters } from '../models/payout-filters.interface';

const toRest = (filterToRest: PayoutFilters, datePipe: DatePipe) => {
  const filter = { ...filterToRest };

  const result = {
    ...filter,
    dateFrom: datePipe.transform(filter.range.startDate, 'yyyy-MM-dd'),
    dateTo: datePipe.transform(filter.range.endDate, 'yyyy-MM-dd'),
    paymentMethodIds: filter.paymentMethodIds?.map((paymentMethod: Entity) => paymentMethod.id),
    paymentNetworkIds: filter.paymentNetworkIds?.map((paymentNetwork: Entity) => paymentNetwork.id),
    statuses: filter.statuses?.map((status: Entity) => status.id),
  };

  delete result.range;

  return result;
};

export const AdminFiltersAdapter = {
  toRest,
};
