import { InjectionToken } from '@angular/core';

import { Observable } from 'rxjs';

import { PayoutResponse } from '../models/payout.interface';
import { PayoutHistory } from '../models/payout-history.interface';
import { PayoutRequest } from '../models/payout-request.interface';
import { PayoutMethod } from '../models/payout-method.interface';
import { PayoutMethodRequest } from '../models/payout-method-request.interface';
import { PayoutBalance } from '../models/payout-balance.interface';

export interface FinanceWebmasterProvider {
  getPayouts(filters: string): Observable<PayoutResponse>;
  makePayout(request: PayoutRequest): Observable<void>;
  getPayoutsHistory(id: number): Observable<PayoutHistory[]>;
  getPayoutsMethod(): Observable<PayoutMethod[]>;
  sendPayoutMethod(request: PayoutMethodRequest): Observable<void>;
  deletePayoutMethod(id: number): Observable<void>;
  setActivePayoutMethod(id: number, isActive: boolean): Observable<void>;
  changePayoutStatus(
    id: number,
    request: { newStatus?: number; comment?: string },
  ): Observable<void>;
  getMyBalance(): Observable<PayoutBalance>;
}

export const FINANCE_WEBMASTER_PROVIDER_TOKEN = new InjectionToken<FinanceWebmasterProvider>(
  'FinanceWebmaster',
);
