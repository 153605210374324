import { InjectionToken } from '@angular/core';

import { PaymentMethod, PayoutPayload, PayoutResponse } from '@rp/shared/models';
import { Observable } from 'rxjs';

export interface PayoutsProvider {
  getPayouts(payload: PayoutPayload): Observable<PayoutResponse>;
  deletePayout(id: number): Observable<void>;
  setActive(id: number, isActive: boolean): Observable<void>;
  createPayout(paymentMethod: PaymentMethod): Observable<PaymentMethod>;
}

export const PAYOUTS_PROVIDER_TOKEN = new InjectionToken<PayoutsProvider>('Payouts');
