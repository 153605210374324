import { Component, TemplateRef, booleanAttribute, input } from '@angular/core';

@Component({
  selector: 'rp-tab',
  standalone: true,
  template: '',
})
export class TabComponent<T> {
  readonly title = input.required<string>();
  readonly active = input(false, { transform: booleanAttribute });
  readonly contentRef = input<TemplateRef<T>>();
  readonly actionsRef = input<TemplateRef<T>>();
  readonly value = input<T>();
}
