import {
  AfterViewInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  input,
  InputSignal,
  InputSignalWithTransform,
  output,
  OutputEmitterRef,
  QueryList,
  signal,
  TemplateRef,
  viewChild,
  WritableSignal,
} from '@angular/core';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource,
} from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { NgTemplateOutlet } from '@angular/common';
import { MatSort, MatSortHeader, Sort } from '@angular/material/sort';
import { MAT_SELECT_CONFIG } from '@angular/material/select';

import { TypeSafeMatCellDefDirective } from '@rp/shared/directives';
import { TranslateModule } from '@ngx-translate/core';
import { DecodedSafeHtmlPipe } from '@rp/shared/pipes';
import { PaginationRequest } from '@rp/shared/models';

import { TableColumn } from './models/table-column.interface';
import { IconComponent } from '../icon';
import { TableAction } from './models/table-action.enum';
import { TableRowAction } from './models/table-row-action.interface';
import { CellComponent } from './components/cell.component';
import { RowDirective } from './directives/row.directive';
import { CustomMatPaginatorIntl } from './intl/custom-paginator.intl';
import { TablePlaceholderComponent } from '../table-placeholder';

@Component({
  selector: 'rp-table',
  standalone: true,
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TablePlaceholderComponent,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderCellDef,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatTable,
    TypeSafeMatCellDefDirective,
    TranslateModule,
    RowDirective,
    NgTemplateOutlet,
    DecodedSafeHtmlPipe,
    IconComponent,
    MatSort,
    MatSortHeader,
    MatPaginator,
  ],
  providers: [
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl },
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'rp-paginator-select' },
    },
  ],
})
export class TableComponent<T> implements AfterViewInit {
  @ContentChildren(RowDirective) set itemsContents(contents: QueryList<RowDirective<T>>) {
    this.dataSource.data = contents?.map(content => content.item());
    this.displayedColumns.set([...this.columns().map(({ columnDef }) => columnDef)]);

    contents.forEach((content: RowDirective<T>, rowIndex: number) => {
      const cells = content.cells.toArray();
      cells.forEach((cell: CellComponent) => {
        this.rowCellsTemplatesMap[cell.cellDef() + '-' + rowIndex] = cell.cellTemplate();
      });
    });
  }

  readonly sortRef = viewChild(MatSort);

  //* Inputs *//
  readonly columns: InputSignal<TableColumn[]> = input.required<TableColumn[]>();
  readonly itemsLength: InputSignal<number> = input.required<number>();
  readonly pageSizeOptions: InputSignal<number[]> = input<number[]>([10, 20, 50]);
  readonly showFirstLastButtons: InputSignalWithTransform<boolean, boolean> = input<
    boolean,
    boolean
  >(true, {
    transform: booleanAttribute,
  });
  readonly titlePlaceholder = input<string>('general.placeholders.noItemsPlaceholderTitle');
  readonly subtitlePlaceholder = input<string>('general.placeholders.noItemsPlaceholderSubTitle');

  readonly rowCellsTemplatesMap: Record<string, TemplateRef<HTMLElement>> = {};

  //* Signals *//
  readonly displayedColumns: WritableSignal<string[]> = signal<string[]>([]);
  readonly pageSize: WritableSignal<number> = signal<number>(10);
  readonly pageIndex: WritableSignal<number> = signal<number>(0);

  // * Outputs *//
  readonly action: OutputEmitterRef<TableRowAction<T>> = output<TableRowAction<T>>();
  readonly sort: OutputEmitterRef<Sort> = output<Sort>();
  readonly pagination = output<PaginationRequest>();

  // * Mat Table *//
  readonly dataSource = new MatTableDataSource<T>([]);

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sortRef();

    this.columns().forEach((column: TableColumn) => {
      if (column.sortActive) {
        this.sortRef().sort({ id: column.columnDef, start: 'asc', disableClear: false });
      }
    });
  }

  onPageChange(pageEvent: PageEvent): void {
    this.pageSize.set(pageEvent.pageSize);
    this.pageIndex.set(pageEvent.pageIndex);
    this.pagination.emit({
      page: pageEvent.pageIndex + 1,
      limit: pageEvent.pageSize,
    });
  }

  onAction(action: TableAction, item: T): void {
    this.action.emit({ action, item });
  }

  sortData(sort: Sort): void {
    this.sort.emit(sort);
  }

  resetPaginator(): void {
    this.pageSize.set(10);
    this.pageIndex.set(0);
  }
}
