import { Injectable, inject } from '@angular/core';

import { PaymentMethod, PayoutPayload, PayoutResponse } from '@rp/shared/models';
import { PAYOUTS_PROVIDER_TOKEN, PayoutsProvider } from '@rp/shared/providers';
import { Observable } from 'rxjs';

@Injectable()
export class PayoutsService implements PayoutsProvider {
  private readonly _payoutsProvider = inject(PAYOUTS_PROVIDER_TOKEN);

  getPayouts(payload: PayoutPayload): Observable<PayoutResponse> {
    return this._payoutsProvider.getPayouts(payload);
  }

  deletePayout(id: number): Observable<void> {
    return this._payoutsProvider.deletePayout(id);
  }

  setActive(id: number, isActive: boolean): Observable<void> {
    return this._payoutsProvider.setActive(id, isActive);
  }

  createPayout(paymentMethod: PaymentMethod): Observable<PaymentMethod> {
    return this._payoutsProvider.createPayout(paymentMethod);
  }
}
