import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CurrencyPipe, DatePipe } from '@angular/common';

import { DateFormat } from '@rp/shared/models';
import { TranslateModule } from '@ngx-translate/core';

import { FinanceStatusComponent } from '../finance-status/finance-status.component';
import { Payout } from '../../models/payout.interface';

@Component({
  selector: 'rp-finance-mobile-view',
  standalone: true,
  templateUrl: './finance-mobile-view.component.html',
  styleUrl: './finance-mobile-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DatePipe, FinanceStatusComponent, TranslateModule, CurrencyPipe],
})
export class FinanceMobileViewComponent {
  DateFormat = DateFormat;

  data = input<Payout>();
  paymentMethods = input<Map<number, string>>(null);
  payoutStatuses = input<Map<number, string>>(null);
}
