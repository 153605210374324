import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AsyncPipe } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HttpErrorResponse } from '@angular/common/http';

import {
  ButtonComponent,
  CellComponent,
  DIALOG_DATA,
  DialogComponent,
  DialogRef,
  IconComponent,
  InputComponent,
  RowDirective,
  SelectComponent,
  TableComponent,
  ToastService,
} from '@rp/shared/components';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Entity, StructureResponse, TargetApp } from '@rp/shared/models';
import {
  CommonMediaQueries,
  HttpErrorHandlerService,
  MediaScreenService,
} from '@rp/shared/services';
import { Observable } from 'rxjs';

import { FinanceStatusComponent } from '../finance-status/finance-status.component';
import { Payout } from '../../models/payout.interface';
import { FinancialStatus } from '../../models/financial-status.enum';
import { FinanceService } from '../../services/finance.service';

@Component({
  selector: 'rp-edit-status-dialog',
  standalone: true,
  templateUrl: './edit-status-dialog.component.html',
  styleUrl: './edit-status-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CellComponent,
    DialogComponent,
    FinanceStatusComponent,
    IconComponent,
    RowDirective,
    TableComponent,
    TranslateModule,
    SelectComponent,
    InputComponent,
    ReactiveFormsModule,
    ButtonComponent,
    AsyncPipe,
  ],
})
export class EditStatusDialogComponent implements OnInit {
  form = new FormGroup({
    newStatus: new FormControl(null, [Validators.required]),
    comment: new FormControl(null, [Validators.required]),
  });

  isMobile$: Observable<boolean>;

  dialogData: {
    target: TargetApp;
    financeService: FinanceService;
    statuses: WritableSignal<Map<number, string>>;
    structures: WritableSignal<StructureResponse>;
    payout: Payout;
  } = inject(DIALOG_DATA);
  dialogRef = inject(DialogRef);

  private _toast = inject(ToastService);
  private _destroyRef = inject(DestroyRef);
  private _httpErrorHandlerService = inject(HttpErrorHandlerService);
  private _mediaScreenService = inject(MediaScreenService);
  private _translateService = inject(TranslateService);

  ngOnInit(): void {
    this.isMobile$ = this._mediaScreenService.mediaMatcher(CommonMediaQueries.XL, true);
  }

  editStatus(): void {
    this.dialogData.financeService
      .getInstance(this.dialogData.target)
      ?.changePayoutStatus(this.dialogData.payout.id, this.form.value)
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: () => {
          this._toast.showToast({
            text: '',
            title: 'actions.toast.successfully',
            type: 'success',
          });
          this.dialogRef.close();
        },
        error: (err: HttpErrorResponse) => this._httpErrorHandlerService.showErrorMessage(err),
      });
  }

  statuses(): Entity[] {
    if (this.dialogData.payout.status === FinancialStatus.PAYOUT_REQUEST_STATUS_NEW) {
      return [
        { id: 2, name: this._translateService.instant('finance.PAYOUT_REQUEST_STATUS_PROGRESS') },
        { id: 3, name: this._translateService.instant('finance.PAYOUT_REQUEST_STATUS_PAID') },
        { id: 4, name: this._translateService.instant('finance.PAYOUT_REQUEST_STATUS_CANCELED') },
      ];
    } else {
      return [
        { id: 1, name: this._translateService.instant('finance.PAYOUT_REQUEST_STATUS_NEW') },
        { id: 3, name: this._translateService.instant('finance.PAYOUT_REQUEST_STATUS_PAID') },
        { id: 4, name: this._translateService.instant('finance.PAYOUT_REQUEST_STATUS_CANCELED') },
      ];
    }
  }
}
