@if (hasRange) {
  <mat-form-field
    [subscriptSizing]="subscriptSizing()"
    [class.filled]="!!startControl.value?.toString().length || !!endControl.value?.toString().length"
    appearance="outline"
    class="rp-date-picker"
  >
    <mat-label>{{ label() }}</mat-label>
    <mat-date-range-input [max]="maxDate()" [rangePicker]="picker" [disabled]="isDisabled()">
      <input matStartDate [formControl]="startControl" [placeholder]="placeholderStart()" (dateChange)="onChange.emit(ngControl.value)" />
      <input matEndDate [formControl]="endControl" [placeholder]="placeholderEnd()" (dateChange)="onChange.emit(ngControl.value)" />
    </mat-date-range-input>
    @if (hint()) {
      <mat-hint>{{ hint() }}</mat-hint>
    }

    @if (clearable() && (startControl.value || endControl.value)) {
      <rp-icon class="calendar-icon" matSuffix [iconName]="icons.ResetFilter" size="s" (click)="onClear()" />
    }

    <mat-datepicker-toggle matIconSuffix [for]="picker" [disableRipple]="true">
      <rp-icon class="calendar-icon" [iconName]="icons.Calendar" size="s" matDatepickerToggleIcon />
    </mat-datepicker-toggle>
    <mat-date-range-picker #picker (opened)="onOpen()" (closed)="onClose()" />

    @if ((startControl.hasError('required') || endControl.hasError('required')) && showErrorMessage()) {
      <mat-error>{{ 'validations.required' | translate }}</mat-error>
    }
  </mat-form-field>
} @else {
  <mat-form-field
    [subscriptSizing]="subscriptSizing()"
    [class.filled]="!!ngControl.control.value?.toString().length"
    appearance="outline"
    class="rp-date-picker"
  >
    <mat-label>{{ label() }}</mat-label>
    <input
      [max]="maxDate()"
      matInput
      [matDatepicker]="picker"
      [formControl]="ngControl.control"
      [errorStateMatcher]="matcher()"
      [placeholder]="placeholder()"
      [disabled]="isDisabled()"
      (dateChange)="onChange.emit($event)"
    />
    @if (hint()) {
      <mat-hint>{{ hint() }}</mat-hint>
    }

    @if (clearable() && (startControl.value || endControl.value)) {
      <rp-icon class="calendar-icon" matSuffix [iconName]="icons.ResetFilter" size="s" (click)="onClear()" />
    }

    <mat-datepicker-toggle matIconSuffix [for]="picker" [disableRipple]="true">
      <rp-icon class="calendar-icon" [iconName]="icons.Calendar" size="s" matDatepickerToggleIcon />
    </mat-datepicker-toggle>
    <mat-datepicker #picker />

    @if (ngControl.control.hasError('required') && showErrorMessage()) {
      <mat-error>{{ 'validations.required' | translate }}</mat-error>
    }
  </mat-form-field>
}
