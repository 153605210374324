<rp-dialog [caption]="'finance.viewDetails' | translate" (closeDialog)="dialogRef.close()">
  <ng-container dialogBody *rpSpinner="isLoading()">
    @if (dialogData.target === targetApp.Webmaster) {
      <div class="header-title">
        {{ dialogData.payout.amountCents | currency: 'USD' }}
        <rp-icon iconName="transfer-arrow" class="header-title__icon" />
        {{ dialogData.payout.walletAddress }}
      </div>
    }
    <rp-table [columns]="columns()" [itemsLength]="items().length">
      @for (item of items(); track $index) {
        <div rpRow [item]="item">
          <rp-cell cellDef="dateTime">
            <div class="font-medium__12">{{ item.date | date: DateFormat.ShortDate }}</div>
            <div>{{ item.date | date: DateFormat.TimeWithSeconds }}</div>
          </rp-cell>
          <rp-cell cellDef="status">
            <rp-finance-status [status]="item.status" />
          </rp-cell>
          <rp-cell cellDef="comment">{{ item.comment }}</rp-cell>
        </div>
      }
    </rp-table>
  </ng-container>
  <ng-container dialogFooter>
    @if (dialogData.target === targetApp.Admin) {
      <rp-button (click)="changeStatus()" [disabled]="isStatusChangeable()">
        {{ 'finance.changeStatus' | translate }}
      </rp-button>
    }
  </ng-container>
</rp-dialog>
