import {
  ChangeDetectionStrategy,
  Component,
  DoCheck,
  booleanAttribute,
  effect,
  input,
  output,
  signal,
} from '@angular/core';
import {
  FormControlDirective,
  FormControlName,
  NgModel,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
  SubscriptSizing,
} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgClass } from '@angular/common';

import { NoopValueAccessorDirective } from '@rp/shared/directives';
import { FieldErrorStateMatcher, injectNgControl } from '@rp/utils';
import { TranslateModule } from '@ngx-translate/core';
import { Validation } from '@rp/shared/validators';

import { IconComponent, IconName } from '../icon';
import { ControlType } from './models/control-type.type';

@Component({
  selector: 'rp-input',
  standalone: true,
  styleUrl: './input.component.scss',
  templateUrl: './input.component.html',
  hostDirectives: [NoopValueAccessorDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    IconComponent,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    TranslateModule,
    NgClass,
  ],
  providers: [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } }],
})
export class InputComponent implements DoCheck {
  ngControl: FormControlDirective | FormControlName | NgModel = injectNgControl();

  iconName = input<string>('');
  type = input<ControlType>('text');
  label = input<string>('');
  hint = input<string>('');
  placeholder = input<string>('');
  matcher = input<FieldErrorStateMatcher>();
  subscriptSizing = input<SubscriptSizing>('fixed');
  isDisabled = input(false, {
    transform: booleanAttribute,
  });
  showErrorMessage = input(true, {
    transform: booleanAttribute,
  });
  clearable = input(false, {
    transform: booleanAttribute,
  });

  firstError = signal<string>('');
  errors = signal<Validation[]>([]);

  readonly validation = Validation;
  readonly icons = IconName;

  readonly iconClick = output<Event>();

  constructor() {
    effect(() => {
      const { control } = this.ngControl;
      if (this.isDisabled()) {
        control.disable();
      } else {
        control.enable();
      }
    });
  }

  ngDoCheck(): void {
    this._handleErrors();
  }

  onIconAction(event: Event): void {
    this.iconClick.emit(event);
  }

  onClear(): void {
    this.ngControl.control.reset();
  }

  private _handleErrors(): void {
    const { control } = this.ngControl;

    if (control.dirty && control.errors) {
      this.errors.set(Object.keys(control.errors ?? {}) as Validation[]);
      this.firstError.set(this.errors().length ? 'validations.' + this.errors()[0] : '');
      control.markAsTouched();
    } else if (control.value) {
      this.errors.set([]);
      this.firstError.set('');
    }
  }
}
