@if (target === targetApp.Webmaster) {
  <rp-balance-cards />
}

<rp-table-wrapper [shouldShowSpinner]="isLoading()">
  <div class="full-width" tableFilter>
    <div class="row space__between table-filters__title">
      {{ 'finance.historyOfPayouts' | translate }}

      @if (target === targetApp.Webmaster) {
        <rp-filters-webmaster (formValue)="handleFilterChanged($event)" (apply)="getPayouts()" />
      }

      @if (target === targetApp.Admin) {
        <div class="row">
          <rp-button type="secondary" [disabled]="isFormEmpty(form()?.value)" class="reset" (click)="onResetFilters()">
            <rp-icon [iconName]="icons.Reset" size="s" />
            {{ 'finance.resetFilters' | translate }}
          </rp-button>

          <rp-button [iconName]="icons.SaveAs" [disabled]="true">
            {{ 'finance.saveReport' | translate }}
          </rp-button>
        </div>
      }
    </div>
    @if (target === targetApp.Admin) {
      <rp-filters-admin [structures]="structures()" (formValue)="handleFilterChanged($event)" (apply)="getPayouts()" />
    }
  </div>

  @if (isDesktop$ | async) {
    <rp-table
      tableBody
      [columns]="columns"
      [itemsLength]="payouts().itemsCount"
      (sort)="onSort($event)"
      (pagination)="onPagination($event)"
      titlePlaceholder="finance.tableTitlePlaceholder"
      subtitlePlaceholder="finance.tableSubtitlePlaceholder"
    >
      @for (item of payouts().payouts; track item.id) {
        <div rpRow [item]="item">
          <rp-cell cellDef="id">{{ item.id }}</rp-cell>
          @if (target === targetApp.Admin) {
            <rp-cell cellDef="webmasterId">
              {{ item.userId }}
            </rp-cell>
          }
          <rp-cell cellDef="date">
            <div class="font-medium__12">{{ item.date | date: DateFormat.ShortDate }}</div>
            <div>{{ item.date | date: DateFormat.TimeWithSeconds }}</div>
          </rp-cell>
          <rp-cell cellDef="method">
            <div class="font-bold__12">{{ paymentMethods()?.get(item.paymentMethodId) }}</div>
          </rp-cell>
          <rp-cell cellDef="network">
            <div class="font-bold__12">{{ paymentNetworks()?.get(item.networkId) }}</div>
          </rp-cell>
          <rp-cell cellDef="walletAddress">
            <div class="row space__between width__245">
              <div class="font-bold__12" [attr.title]="item.walletAddress">{{ item.walletAddress }}</div>
              <rp-icon class="cursor__pointer" [iconName]="icons.Copy" size="s" (click)="clipboardService.copy(item.walletAddress)" />
            </div>
          </rp-cell>
          <rp-cell cellDef="status">
            <rp-finance-status [status]="item.status" />
          </rp-cell>
          <rp-cell cellDef="amount">
            <div class="font-medium__14" [attr.title]="item.amountCents | currency: 'USD'">{{ item.amountCents | currency: 'USD' }}</div>
          </rp-cell>
          <rp-cell cellDef="action">
            <rp-icon class="font-medium__14 cursor__pointer" iconName="eye" (click)="openDetails(item)" />
          </rp-cell>
        </div>
      }
      @if (target === targetApp.Webmaster) {
        <rp-button underPlaceholder type="secondary" routerLink="/profile" [queryParams]="{ tab: 'paymentMethod' }">
          {{ 'profile.addPaymentMethod' | translate }}
        </rp-button>
      }
    </rp-table>
  } @else {
    <rp-table-mobile
      tableBody
      [data]="payouts().payouts"
      [itemsLength]="payouts().itemsCount"
      [template]="templateRef"
      titlePlaceholder="finance.tableTitlePlaceholder"
      subtitlePlaceholder="finance.tableSubtitlePlaceholder"
    >
      @if (target === targetApp.Webmaster) {
        <rp-button underPlaceholder type="secondary" routerLink="/profile" [queryParams]="{ tab: 'paymentMethod' }">
          {{ 'profile.addPaymentMethod' | translate }}
        </rp-button>
      }
    </rp-table-mobile>
  }
</rp-table-wrapper>

<ng-template #templateRef let-data="data">
  <rp-finance-mobile-view
    [data]="data"
    (click)="openDetails(data)"
    [paymentMethods]="paymentMethods()"
    [payoutStatuses]="payoutStatuses()"
  />
</ng-template>
