import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  booleanAttribute,
  effect,
  inject,
  input,
  OnInit,
  output,
  viewChild,
} from '@angular/core';
import {
  FormControl,
  FormControlDirective,
  FormControlName,
  NgModel,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
  SubscriptSizing,
} from '@angular/material/form-field';
import {
  MatDatepickerModule,
  MatDateRangeInput,
  MatDatepickerInputEvent,
} from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { FieldErrorStateMatcher, injectNgControl } from '@rp/utils';
import { NoopValueAccessorDirective } from '@rp/shared/directives';
import { filter } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

import { IconComponent, IconName } from '../icon';

@Component({
  selector: 'rp-date-picker',
  standalone: true,
  templateUrl: './date-picker.component.html',
  hostDirectives: [NoopValueAccessorDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    ReactiveFormsModule,
    IconComponent,
    TranslateModule,
  ],
  providers: [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always' } }],
})
export class DatePickerComponent implements OnInit {
  dateRangeInput = viewChild(MatDateRangeInput);

  maxDate = input<Date | null>(new Date());
  label = input<string>('');
  hint = input<string>('');
  placeholder = input<string>('');
  placeholderStart = input<string>('');
  placeholderEnd = input<string>('');
  startDateKey = input<string>('startDate');
  endDateKey = input<string>('endDate');
  subscriptSizing = input<SubscriptSizing>('fixed');
  matcher = input<FieldErrorStateMatcher>();
  isDisabled = input(false, {
    transform: booleanAttribute,
  });
  isRequired = input(false, {
    transform: booleanAttribute,
  });
  showErrorMessage = input(true, {
    transform: booleanAttribute,
  });
  clearable = input(false, {
    transform: booleanAttribute,
  });

  onChange = output<MatDatepickerInputEvent<Date, Date>>();

  hasRange = false;

  ngControl: FormControlDirective | FormControlName | NgModel = injectNgControl();
  startControl = new FormControl<Date | null>(null);
  endControl = new FormControl<Date | null>(null);
  icons = IconName;

  private _destroyRef = inject(DestroyRef);
  private _timeOut: ReturnType<typeof setTimeout>;

  constructor() {
    effect(() => {
      const { control } = this.ngControl;

      if (this.isDisabled()) {
        control.disable();
        this.startControl.disable();
        this.endControl.disable();
      } else {
        control.enable();
        this.startControl.enable();
        this.endControl.enable();
      }

      if (this.isRequired() || this.ngControl.control.errors?.['required']) {
        control.setValidators(Validators.required);
        this.startControl.setValidators(Validators.required);
        this.endControl.setValidators(Validators.required);
      } else {
        control.removeValidators(Validators.required);
        this.startControl.removeValidators(Validators.required);
        this.endControl.removeValidators(Validators.required);
      }

      if (this.placeholderStart() || this.placeholderEnd()) {
        // Trigger change detection to update the view when the placeholder changes
        this._updateDateRangeInputView();
      }
    });
  }

  ngOnInit(): void {
    this._setRangeControlsValues();
    this._listenRangeControlsChanges();

    this.ngControl.valueChanges?.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(e => {
      if (e === null) {
        this.startControl.setValue(null);
        this.endControl.setValue(null);
      }
    });
  }

  onOpen(): void {
    this._transformMonthLabel();
  }

  onClose(): void {
    clearTimeout(this._timeOut);
  }

  onClear(): void {
    this.ngControl.control.reset();
    this.onChange.emit(null);
  }

  private _transformMonthLabel(): void {
    this._timeOut = setTimeout(() => {
      const monthLabelElement = document.querySelector(
        '.mat-calendar-header .mdc-button__label span',
      ) as HTMLElement;
      const textLabel = monthLabelElement.textContent;

      monthLabelElement.innerText =
        textLabel.charAt(0).toUpperCase() + textLabel.slice(1).toLocaleLowerCase();
    }, 0);
  }

  private _setRangeControlsValues(): void {
    this.hasRange =
      !!this.ngControl.control.value && !!Object.keys(this.ngControl.control.value).length;
    if (this.hasRange && this.startDateKey() && this.endDateKey()) {
      this.startControl.setValue(this.ngControl.value?.[this.startDateKey()]);
      this.endControl.setValue(this.ngControl.value?.[this.endDateKey()]);
    }
  }

  private _listenRangeControlsChanges(): void {
    this.startControl.valueChanges
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter(() => this.hasRange),
      )
      .subscribe(value => {
        this.ngControl.control.setValue({
          ...this.ngControl.control.value,
          [this.startDateKey()]: value,
        });
        this.ngControl.control.setErrors(this.startControl.errors);

        this._updateDateRangeInputView();
      });

    this.endControl.valueChanges
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter(() => this.hasRange),
      )
      .subscribe(value => {
        this.ngControl.control.setValue({
          ...this.ngControl.control.value,
          [this.endDateKey()]: value,
        });
        this.ngControl.control.setErrors(this.endControl.errors);

        this._updateDateRangeInputView();
      });
  }

  private _updateDateRangeInputView(): void {
    this.dateRangeInput()['_changeDetectorRef'].markForCheck();
  }
}
