import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DateAdapter } from '@angular/material/core';

import { TranslateService } from '@ngx-translate/core';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { LANGUAGE_KEY, Languages } from '@rp/shared/models';
import { environment } from '@rp/environments';

import { LocalStorageService } from './local-storage.service';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  private _translateService = inject(TranslateService);
  private _localStorageService = inject(LocalStorageService);
  private _httpClient = inject(HttpClient);
  private _dateAdapter = inject(DateAdapter);

  getCurrentLanguage(): Languages {
    return (this._localStorageService.getStringValue(LANGUAGE_KEY) ||
      environment.defaultLanguage) as Languages;
  }

  setLanguage(language: Languages): void {
    this._translateService.use(language);
    this._localStorageService.setValue(LANGUAGE_KEY, language);
    this._dateAdapter.setLocale(language);
  }

  setTranslation(language: string): Observable<boolean> {
    return this._httpClient.get(`/i18n/${language}.json`).pipe(
      switchMap(translations => {
        this._translateService.setTranslation(language, translations);

        return of(true);
      }),
      catchError(error => {
        console.error('Error loading translations', error);

        return of(false);
      }),
    );
  }
}
