<div class="row">
  <rp-button type="text" [iconName]="icons.ArrowLeft" size="s" routerLink="../">
    {{ 'general.buttons.back' | translate }}
  </rp-button>

  <div>
    <div class="title">{{ 'finance.payout' | translate }}</div>
  </div>

  <div class="width__40"></div>
</div>

<div class="row">
  <div></div>

  <div class="flex__centered">
    <div class="sub-title">{{ payout().amountCents | currency: 'USD' }}</div>
    <rp-finance-status [status]="payout().status" />
  </div>

  <div></div>
</div>

<div class="caption">
  <div class="caption__label">{{ 'finance.id' | translate }}</div>
  <div class="caption__value">{{ payout().id }}</div>
</div>

@if (target === targetApp.Admin) {
  <div class="caption">
    <div class="caption__label">{{ 'finance.webmasterId' | translate }}</div>
    <div class="caption__value">{{ payout().walletId }}</div>
  </div>
}

<div class="caption">
  <div class="caption__label">{{ 'finance.date' | translate }}</div>
  <div class="caption__value">{{ payout().date | date: DateFormat.HourWithSeconds }}</div>
</div>

<div class="caption">
  <div class="caption__label">{{ 'finance.method' | translate }}</div>
  <div class="caption__value">{{ payout().paymentMethodId }}</div>
</div>

<div class="caption">
  <div class="caption__label">{{ 'finance.network' | translate }}</div>
  <div class="caption__value">{{ payout().networkId }}</div>
</div>

@if (this.target !== targetApp.Webmaster) {
  <div class="caption">
    <div class="caption__label">{{ 'finance.walletAddress' | translate }}</div>
    <div class="caption__value">
      {{ payout().walletAddress }}
      <rp-icon class="cursor__pointer" [iconName]="icons.Copy" size="s" (click)="clipboardService.copy(payout().walletAddress)" />
    </div>
  </div>
}

<rp-button type="secondary" fullWidth class="offset__top" (click)="openDetails()">
  <rp-icon iconName="eye" />
  {{ 'finance.viewDetails' | translate }}
</rp-button>
