import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnInit,
  signal,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HttpErrorResponse } from '@angular/common/http';

import {
  ButtonComponent,
  InputComponent,
  IntegerOnlyDirective,
  SelectComponent,
  ToastService,
} from '@rp/shared/components';
import { TranslateModule } from '@ngx-translate/core';
import { HttpErrorHandlerService, PayoutsService, StructuresService } from '@rp/shared/services';
import { DEFAULT_PAGINATION, PaginationRequest, PaymentMethod } from '@rp/shared/models';
import { HttpPayoutsService, PAYOUTS_PROVIDER_TOKEN } from '@rp/shared/providers';
import { FieldErrorStateMatcher } from '@rp/utils';

import { FinanceService } from '../../services/finance.service';

@Component({
  selector: 'rp-payout-card',
  standalone: true,
  templateUrl: './payout-card.component.html',
  styleUrl: './payout-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SelectComponent,
    ReactiveFormsModule,
    InputComponent,
    ButtonComponent,
    TranslateModule,
    IntegerOnlyDirective,
  ],
  providers: [{ provide: PAYOUTS_PROVIDER_TOKEN, useClass: HttpPayoutsService }, PayoutsService],
})
export class PayoutCardComponent implements OnInit {
  form = new FormGroup({
    amountCents: new FormControl(null, [Validators.required, Validators.min(30)]),
    walletId: new FormControl(null, [Validators.required]),
  });

  paymentMethods = signal<PaymentMethod[]>([]);

  activatedRoute = inject(ActivatedRoute);
  financeService = inject(FinanceService);
  httpErrorHandlerService = inject(HttpErrorHandlerService);
  structures = inject(StructuresService);

  matcher = new FieldErrorStateMatcher();

  private readonly _toast = inject(ToastService);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _payoutsService = inject(PayoutsService);
  private readonly _httpErrorHandlerService = inject(HttpErrorHandlerService);

  ngOnInit(): void {
    this._getPayouts();
  }

  makePayout(): void {
    this.financeService
      .getInstance(this.activatedRoute.snapshot.data['target'])
      .makePayout({
        ...this.form.value,
        amountCents: this._toCents(Number(this.form.value.amountCents)),
      })
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: () =>
          this._toast.showToast({
            text: '',
            title: 'actions.toast.successfully',
            type: 'success',
          }),
        error: err =>
          this._toast.showToast({
            type: 'error',
            text:
              typeof err.error.error.data === 'string'
                ? err.error.error.data
                : (err.error.error.data.amountCents ?? err.error.error.data.walletId).join(),
          }),
      });
  }

  onScroll(): void {
    if (this.paymentMethods().length % 5 === 0) {
      this._getPayouts({ page: 1, limit: this.paymentMethods().length + 5 });
    }
  }

  private _getPayouts(pagination?: PaginationRequest): void {
    this._payoutsService
      .getPayouts(pagination ? pagination : DEFAULT_PAGINATION)
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe({
        next: ({ payoutMethod }) => this.paymentMethods.set(payoutMethod),
        error: (error: HttpErrorResponse) => this._httpErrorHandlerService.showErrorMessage(error),
      });
  }

  private _toCents(value: number): number {
    return value * 100;
  }
}
