import { trigger, state, style, transition, animate } from '@angular/animations';

export const accordionAnimation = trigger('accordionContent', [
  state(
    'open',
    style({
      height: '*',
      display: 'block',
      padding: '15px',
    }),
  ),
  state(
    'close',
    style({
      height: '0px',
      display: 'none',
      padding: '0 15px',
    }),
  ),
  transition('open <=> closed', [animate('0.3s ease-in-out')]),
]);
